import React from "react";
import { graphql, Link } from "gatsby";
import GsHero from "../components/GsHero";
import "../styles/styles.css"

// import { GatsbyImage } from 'gatsby-plugin-image';
import Image from "gatsby-plugin-sanity-image";
import { SEO } from "../components/seo";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import ContactDetails from "../components/ContactDetails";
import Topics from "../components/Topics";

const Gutschein = ({ data }) => {

    const imgUrl = data.sanityLevelOne.image;
    console.log(imgUrl);

    return (
      <>
        <NavBarTwo />
        <GsHero imgUrl={imgUrl}/>






<section id="contacts" className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
        <div className="container  max-w-7xl px-5 py-24 mx-auto flex flex-col mb-24 md:mb-64">
          <div className="lg:w-4/6 mx-auto">
            <div className="flex flex-col sm:flex-row mt-10">
              <ContactDetails />
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
                <p className="leading-relaxed text-lg mb-4 text-white">Jetzt Gutschein für Ihren gratis D2C Check-Up einlösen. Sichern Sie sich noch heute Ihr persönliches Gespräch mit unseren Experten. Mein Team und ich freuen uns auf Sie!  </p>
                <a className="cursor-pointer text-primary inline-flex items-center" href="mailto:hallo@digitscom.de?subject=Gutschein D2C Checkup&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
            ich möchte gerne Ihr Angebot für einen gratis D2C Check-Up wahrnehmen.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A">Jetzt Gutschein einlösen und Termin vereinbaren
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <div className="mark">


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




        <Footer />
    </>
     
    )
}

export default Gutschein;

export const dataGutschein = graphql`
query MyGutscheinQuery {
  sanityLevelOne(id: {eq: "-b7b5dde3-d424-51a0-b7f4-2cbb53954c6e"}) {
    id
    image {
        ...ImageWithPreview
    }
    imageTwo {
        ...ImageWithPreview
    }
    intro_content
    intro_title
    meta_description
    title
  }
}
`