import React from "react";
import Image from "gatsby-plugin-sanity-image";

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'


export default function GsHero(data) {
 
  return (
    <>

<div className="bg-gradient-to-r from-orange-400 via-amber-300 to-amber-500">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Gutschein für Gratis
            <br />
            D2C Check-up erhalten?
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            Markenpotentiale optimal nutzen. Mit Direktvermarktung zu mehr Wachstum und Stabilität.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
  
            <a
              href="mailto:hallo@digitscom.de?subject=Gutschein D2C Checkup&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
              ich möchte gerne Ihr Angebot für einen gratis D2C Check-Up wahrnehmen.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A" 
              className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Jetzt einlösen
            </a>
            <a href="#Main" className="text-sm font-semibold leading-6 text-gray-600">
              mehr erfahren <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>




    <div className="bg-white px-6 md:py-24 py-16 lg:px-8">
    <div id="Main" className="mx-auto max-w-3xl leading-7 relative ">
        <div className="rotate-12 md:-top-10 right-0  md:absolute md:left-80">
        <Image className="drop-shadow-2xl mb-24  rounded-sm mx-auto" {...data.imgUrl} width={450} style={{
        width: '90%',
        objectFit: 'cover',
        opacity: '1',
        transition: 'opacity 500ms ease 0s'
    }} alt="Gutschein für D2C Check-Up" /> 
        </div>

             <h1 className="text-base  title-font font-semibold leading-10 text-primary">Gutschein für Gratis D2C Check-up</h1>
              <p className="tracking-tight md:leading-10title-font sm:text-5xl text-3xl mb-4 font-medium text-gray-900">Hersteller
        <br/> Großhändler
        <br/> Markeninhaber 
      </p>

      <p className="md:mt-36 text-lg leading-8 text-gray-600">
              Sie haben einen Gutschein von uns erhalten und mindestens eine dieser Bezeichnungen trifft auf Ihr Geschäft zu? Dann sind Sie hier genau richtig:<br/><br/> 


<span className="font-bold">Nehmen Sie unser Angebot für einen gratis D2C Check-Up wahr, und finden Sie heraus, wie Sie die Chancen der Direktvermarktung als Treiber für mehr Umsatz und Stabilität für Ihr Unternehmen nutzen können.</span><br/><br/>


Auch mit begrenztem Budget und geringen Personalressourcen ist ein erfolgreiches Wachstum durch die sogenannte Vorwärts-Integration möglich. Als zuverlässige Partner in der Planung und Umsetzung von D2C Projekten bieten wir unseren Kunden individuell passende Modelle und Strategien über ein breites Spektrum an Branchen hinweg.
              </p>

<br/>
<br/>

              <h2 class="sm:text-2xl text-2xl text-gray-900 font-medium title-font inline sm:align-baseline ">Was genau ist der Digitscom D2C Check-Up?</h2>
<p className="text-lg leading-8 text-gray-600 mt-4">In  ca. 60 bis 90 Minuten betrachten wir in einem Online-Termin gemeinsam mit Ihnen die folgenden Schlüssel-Aspekte Ihres Unternehmens aus der D2C Perspektive:</p>
    <br/>
<ol className="list-decimal ml-8 text-lg leading-8 text-gray-600">
    <li>Vertriebskanäle</li>
    <li>Markenpflege</li>
    <li>Kundenbindung</li>
    <li>Infrastruktur (Logistik u.a.)</li>
</ol>
<br/>
<p className="text-lg leading-8 text-gray-600">
Ziel hierbei ist es einen ersten Eindruck über die Stärken und Entwicklungspotentiale Ihres Unternehmens für den Einstieg in die Direktvermarktung zu gewinnen:<br/>
Welche bereits bestehenden Strukturen können besser ausgeschöpft und ausgebaut werden?<br></br> 
Welches Modell und welche Strategie sind für Sie die Richtigen?
<br/>
Welche Schritte sind für eine erfolgreiche Umsetzung notwendig?<br/><br/>
<p>Neugierig geworden? Wir freuen uns auf Ihre Rückmeldung!</p>
</p>
<br/>

<div className="inline-block">
<a
    href="mailto:hallo@digitscom.de?subject=Gutschein D2C Checkup&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
    ich möchte gerne Ihr Angebot für einen gratis D2C Check-Up wahrnehmen.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A" 
    className="rounded-md bg-primary px-3.5 py-2.5 text-md font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
  >    Jetzt D2C Check-Up sichern
  </a>
  <br/>
  <span className="block mt-2 text-center text-gray-400 text-sm">(öffnet E-Mail Client)</span>
  </div>
    </div>
    </div>

  

 


    </>
  )
}
